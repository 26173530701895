<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          placeholder="请输入标题"
          show-count
          clearable
          v-model:value="formValue.introduce"
        />
        
      </NFormItem>
      <NFormItem label="类型：">
        <div style="width: 200px">
          <NSelect :options="typeOptions" v-model:value="formValue.school_id" placeholder="请输入或选择学校名称" filterable clearable/>
        </div>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <div class="account-null-btn"></div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { GetBannerIndex, GetBannerSchoolIndex } from "@/api/feedback.js";  
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "顺序",
      key: "sort",
    },
    {
      title: "问题描述",
      width: 300,
      key: "introduce",
      className: 'introduce'
    },
    {
      title: "联系方式",
      key: "phone",
    },
    {
      title: "提交账号",
      key: "admin_username",
    },
    {
      title: "所属学校",
      key: "school_name",
    },
    {
    title: "提交时间",
    render: (row) =>
      h(NTime, {
        time: (Number(row.create_time) || 0) * 1000,
      }),
    },
    {
      title: '操作',
      width: 100,
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

const typeOptions = ref([])

// 初始化学校列表
function InitSchool(){
  loading.value = true;
  GetBannerSchoolIndex({"page_size":99999})
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        res.data.list.forEach(item=>{
          // console.log('item',item);
          let data = {
            label:item.school_name,
            value:item.id,
          }
          typeOptions.value.push(data)
        })
      }
    })
    .catch((reject) => {});
};
InitSchool()

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetBannerIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        res.data.list.forEach((item, index) => {
          item.sort = index + 1;
        });
        Datas.value = res.data.list
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});
};
updateList();


//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'info', onClick: () => SeeDetails(row) }, { default: () => '查看详情' })
  if (ownUrlPermission('feedback/detail')){
    tempArr.push(arr1)
  }
  return tempArr
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(row) {
  let id = row.id
  router.push({
    path:'/problems/index/addedit/',
    query:{id:id}
  })
}

// 查看详情
function SeeDetails(row) {
  let id = row.id
  window.open("/full-page/feedback-details?id="+id,"_blank"); 
}

// 翻页 搜索参数集
var ParameterSet = {school_id:''}

// 搜索
function handleSearch () {
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet)
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  page_size: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;

};
const handlePageChange = (page_size) => {
  pagination.page_size = page_size;
  formValue.page_size = page_size;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};


// 分页结束-------------------

const getDefaultFormValue = () => ({
  introduce: "",
  school_id: null,
  page: 1,
  page_size: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  handleSearch();
};

defineExpose({
  getSearchParams,
});

</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
</style>
<style>
#account-management .n-data-table .n-data-table-td[data-col-key="introduce"]{
  max-height: 83px;
  padding-bottom: 20px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}
</style>